<template>
  <div>
    <div class="row g-0" style="background-color: #335ba3;">
      <div class="col-1"></div>
      <div class="col-10">
      <h2>What We Offer</h2>
      </div>
    </div>
    <div class="row g-0" style="background-color: #335ba3;">
      <div class="col-1"></div>
      <div class="col-10">
        <h4>Heating oil (Kerosene)</h4>
      <p> 
        Here at Bens Fuels we know that life can get busy and can throw some unexpected situations your 
        way, so we pride ourselves on being able to offer same day deliveries if ordered before 10am and 
        next day deliveries to meet all of your last-minute heating oil needs. 
      </p>
      </div>
      <div class="col-1"></div>
    </div>
    <div class="row g-0" style="background-color: #335ba3;">
      <div class="col-1"></div>
      <div class="col-10">
        <h4>Gas oil (Red diesel) </h4>
      <p>
        We offer top grade EN590 gas oil (red diesel) to farming and commercial premises. 
        We currently supply gas oil to many films and tv productions throughout the home counties and beyond. 
        All our gas oil is treated with additive and regularly tested.
        N.B Gas oil (red diesel is not for road use)
      </p>
      </div>
      <div class="col-1"></div>
    </div>
    <div class="row g-0" style="background-color: #335ba3;">
      <div class="col-1"></div>
      <div class="col-10">
        <h4>HVO and GLT</h4>
        <p>
        HVO fuel, also known as hydrotreated vegetable oil or HVO, is an advanced renewable diesel fuel derived 
        from 100% renewable waste. FAME free.
        Shell’s gas-to-liquids (GTL) technology converts natural gas into high-quality liquid products that would 
        otherwise be made from crude oil. GTL has a whole range of other benefits for businesses, communities and 
        air quality.
      </p>
      </div>
      <div class="col-1"></div>
    </div>
    <div class="row g-0" style="background-color: #335ba3;">
      <div class="col-1"></div>
      <div class="col-10">
        <h4>Extras</h4>
        <p>
        <strong>Fuel additives</strong> - We supply a range of additives to get the best performance out of your fuel. 
        Additives are available for use in your homes or commercial vehicles and much more. Please get in 
        touch for more information.
        <br/>
        <strong>Fuel testing</strong> - We are able to offer a fuel testing services if you are having concerns or problems 
        with the quality of your fuel.
      </p>
      </div>
      <div class="col-1"></div>
    </div>
  </div>
</template>

<script>
export default {
    name: 'Services'
}
</script>

<style scoped>
h2
{
  color: white;
  padding-top: 15px;
  padding-bottom: 15px;
}

h4
{
  color: white;
  padding-bottom: 5px;
}

p
{
  color: white;
  font-weight: 350;
  font-size: 1.15em;
  line-height: 32px;
  padding-bottom: 10px;
}
</style>